import hyRequest from '@/service';
import {
  IProDataParams,
  IProData,
  IElectric,
  ITrendParams,
  ITrendDataItem,
  ITrendItem,
  IMixtureParams,
  IMixtureItem,
  ISaleStatisticsItem,
  IReceiveStatisticsData
} from './type';

enum DashboardApi {
  getMortarProData = '/product/mortarProData',
  getMortarElectric = '/product/mortarElectric',
  getMortarElectricTrend = '/product/mortarElectricTrend',
  getMortarReceiveTrend = '/product/mortarReceiveTrend',
  getMortarMixture = '/product/mortarMixture',
  getMortarReceiveStatistics = '/product/mortarReceiveStatistics',
  getMortarSaleStatistics = '/product/mortarSaleStatistics'
}

// 商砼产量及使用量统计
export function getMortarProData(data: IProDataParams) {
  return hyRequest.post<IProData>({
    url: DashboardApi.getMortarProData,
    data
  });
}

//商砼耗电量统计
export function getMortarElectric(data: IProDataParams) {
  return hyRequest.post<IElectric>({
    url: DashboardApi.getMortarElectric,
    data
  });
}
// 商砼耗电量趋势图
export function getMortarElectricTrend(data: ITrendParams) {
  return hyRequest.post<{ list: ITrendDataItem[] }>({
    url: DashboardApi.getMortarElectricTrend,
    data
  });
}

//砂浆原材料消耗趋势
export function getMortarReceiveTrend(data: ITrendParams) {
  return hyRequest.post<{ list: ITrendItem[] }>({
    url: DashboardApi.getMortarReceiveTrend,
    data
  });
}

//砂浆配比
export function getMortarMixture(data: IMixtureParams) {
  return hyRequest.post<{ list: IMixtureItem[] }>({
    url: DashboardApi.getMortarMixture,
    data
  });
}

//商砼原材料采购统计
export function getMortarReceiveStatistics(data: IProDataParams) {
  return hyRequest.post<IReceiveStatisticsData>({
    url: DashboardApi.getMortarReceiveStatistics,
    data
  });
}

//商砼销售统计
export function getMortarSaleStatistics(data: IProDataParams) {
  return hyRequest.post<{ list: ISaleStatisticsItem[] }>({
    url: DashboardApi.getMortarSaleStatistics,
    data
  });
}
