import {
  ISales,
  // IAccount,
  ISaleData,
  IReceiving,
  IProduction,
  EPermission,
  ISaleParams,
  IAttendance,
  ISaleSummary,
  IProSumaryData,
  ILineTimeItem,
  IReceiveSumary,
  IPurchaseSumary,
  ICollectionInfo,
  ILineStatusData,
  IAttendanceParams,
  TShowBlueSkyBudget
  // IProSumary,
} from './type';
import hyRequest, { hyRequest1, hyRequest2 } from '@/service';

enum DashboardApi {
  getSales = '/ws/report/sales/findDaySales',
  getProduction = '/ws/report/sales/findDayProduction',
  getReceiving = '/ws/report/receivingDay',
  getAccount = '/report/report/userFinanceAccountByType',
  getLineStatus = '/product/lineStatus',
  getLineTimes = '/blueSkrLed/lineTimes',
  getAttendanceStatistics = '/oa/attendanceStatistics',
  getSaleSummary = '/saleData/saleSummary',
  getCommercialSale = '/saleData/commercialSaleData',
  getMortarSale = '/saleData/mortarSaleData',
  getAggregateSale = '/saleData/aggregateSaleData',
  getPurchaseSummary = '/purchaseData/purchaseSummary',
  getReceiveSummary = '/saleData/receiveSummary',
  getProSumary = '/product/productDataSummary',
  getMoneyEditPermission = '/sysRoleApp/canUpdateCollectData', // 收款修改权限
  getCollectionInfo = '/collectManage/queryCollectManageByDate', // 获取收款信息
  editCollectionInfo = '/collectManage/saveCollectManage', // 修改收款信息
  canShowBlueSkyBudget = '/sysRoleApp/canShowBlueSkyBudget'
}

// 获取骨料产量、耗电量、吨耗电
export function getSales(params: { startTime: string }) {
  return hyRequest2.get<ISales>({
    url: DashboardApi.getSales,
    params
  });
}

// 产量汇总
export function getProSum(params: { date: string }) {
  return hyRequest.post<IProSumaryData>({
    url: DashboardApi.getProSumary,
    data: params
  });
}

//
export function getProduction(params: { startTime: string }) {
  return hyRequest2.get<IProduction>({
    url: DashboardApi.getProduction,
    params
  });
}
export function getReceiving(params: { startTime: string }) {
  return hyRequest2.get<IReceiving>({
    url: DashboardApi.getReceiving,
    params
  });
}
export function getAccount(params: {
  search_day: string;
  search_month: string;
}) {
  return hyRequest1.get<IReceiving>({
    url: DashboardApi.getAccount,
    params
  });
}

//查询产线状态
export function getLineStatus() {
  return hyRequest.post<ILineStatusData>({
    url: DashboardApi.getLineStatus
  });
}
export function getLineTimes() {
  return hyRequest.post<{ list: ILineTimeItem[] }>({
    url: DashboardApi.getLineTimes
  });
}

//考勤统计
export function getAttendanceStatistics(params: IAttendanceParams) {
  return hyRequest.post<IAttendance>({
    url: DashboardApi.getAttendanceStatistics,
    data: params
  });
}

//销售汇总数据

export function getSaleSummary(params: IAttendanceParams) {
  return hyRequest.post<ISaleSummary>({
    url: DashboardApi.getSaleSummary,
    data: params
  });
}

//骨料销售数据
export function getAggregateSale(params: ISaleParams) {
  return hyRequest.post<ISaleData>({
    url: DashboardApi.getAggregateSale,
    data: params
  });
}
//砂浆销售数据
export function getMortarSale(params: ISaleParams) {
  return hyRequest.post<ISaleData>({
    url: DashboardApi.getMortarSale,
    data: params
  });
}
//商砼销售数据
export function getCommercialSale(params: ISaleParams) {
  return hyRequest.post<ISaleData>({
    url: DashboardApi.getCommercialSale,
    data: params
  });
}

//采购汇总数据
export function getPurchaseSummary(params: IAttendanceParams) {
  return hyRequest.post<IPurchaseSumary>({
    url: DashboardApi.getPurchaseSummary,
    data: params
  });
}

//领用汇总数据
export function getReceiveSummary(params: IAttendanceParams) {
  return hyRequest.post<IReceiveSumary>({
    url: DashboardApi.getReceiveSummary,
    data: params
  });
}

// 获取是否有修改收款权限
export function getMoneyEditPermission() {
  return hyRequest.post<EPermission>({
    url: DashboardApi.getMoneyEditPermission
  });
}

// 获取收款信息
export function getCollectionInfo(data: IAttendanceParams) {
  return hyRequest.post<ICollectionInfo>({
    url: DashboardApi.getCollectionInfo,
    data
  });
}

// 修改收款信息
export function editCollectionInfo(data: ICollectionInfo) {
  return hyRequest.post({
    url: DashboardApi.editCollectionInfo,
    data
  });
}

// 是否有蓝天预算权限
export function canShowBlueSkyBudget() {
  return hyRequest.post<TShowBlueSkyBudget>({
    url: DashboardApi.canShowBlueSkyBudget
  });
}
