import hyRequest from '@/service';
import {
  IProData,
  IElectric,
  ITrendItem,
  ITrendParams,
  IMixtureItem,
  ITrendDataItem,
  IProDataParams,
  IMixtureParams,
  ISaleStatisticsItem,
  IReceiveStatisticsData
} from './type';

enum DashboardApi {
  getCommercialProData = '/product/commercialProData',
  getCommercialElectric = '/product/commercialElectric',
  getCommercialElectricTrend = '/product/commercialElectricTrend',
  getCommercialReceiveTrend = '/product/commercialReceiveTrend',
  getCommercialMixture = '/product/commercialMixture',
  getCommercialReceiveStatistics = '/product/commercialReceiveStatistics',
  getCommercialSaleStatistics = '/product/commercialSaleStatistics'
}

// 砂浆产量及使用量统计
export function getCommercialProData(data: IProDataParams) {
  return hyRequest.post<IProData>({
    url: DashboardApi.getCommercialProData,
    data
  });
}

//商砼耗电量统计
export function getCommercialElectric(data: IProDataParams) {
  return hyRequest.post<IElectric>({
    url: DashboardApi.getCommercialElectric,
    data
  });
}

// 商砼耗电量趋势图
export function getCommercialElectricTrend(data: ITrendParams) {
  return hyRequest.post<{ list: ITrendDataItem[] }>({
    url: DashboardApi.getCommercialElectricTrend,
    data
  });
}

//商砼原材料消耗趋势
export function getCommercialReceiveTrend(data: ITrendParams) {
  return hyRequest.post<{ list: ITrendItem[] }>({
    url: DashboardApi.getCommercialReceiveTrend,
    data
  });
}

//商砼配比
export function getCommercialMixture(data: IMixtureParams) {
  return hyRequest.post<{ list: IMixtureItem[] }>({
    url: DashboardApi.getCommercialMixture,
    data
  });
}

//商砼原材料采购统计

export function getCommercialReceiveStatistics(data: IProDataParams) {
  return hyRequest.post<IReceiveStatisticsData>({
    url: DashboardApi.getCommercialReceiveStatistics,
    data
  });
}

//商砼销售统计
export function getCommercialSaleStatistics(data: IProDataParams) {
  return hyRequest.post<{ list: ISaleStatisticsItem[] }>({
    url: DashboardApi.getCommercialSaleStatistics,
    data
  });
}
